
//import ConstUtils from '@/utils/ConstUtils.js';

export default class Utils {

  static SortByName = function(a,b) {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  }
  
  static SortQuestionByPart = function(a, b) {
    return a.part - b.part;
  }
  
  static SortByCreatedDate = function(a, b) {
    if (a.createdDate > b.createdDate) {
      return -1;
    }
    if (a.createdDate < b.createdDate) {
      return 1;
    }
    return 0;
  }
}