<template>
  <div>
  
    <Box>
      <SpacerRow />
      <ErrorRow :error="error" />
      <SuccessRow :message="message" />
    </Box>
  
    <!-- Menu -->
    <Box :showBorder="true" v-if="!isLoadingData">
      <Row>
        <Column :width="11" />
        <Column :width="2">
          <InspectionPdfLink :inspection="inspection.data" :disabled="isLoadingData"/>
        </Column>
        <Column :width="2">
          <Button v-on:click="refresh()" :disabled="isLoadingData">
          Refresh
          </Button>
        </Column>
      </Row>
    </Box>
    
    <Box>
      <LoadingRow :showBorder="true" v-if="isLoadingData"> {{ loadingMessage }} </LoadingRow>
    </Box>
          
    <Box v-if="!isLoadingData" :showBorder="true">
      <!-- Company Details-->
      <TitleRow align="left" >Company</TitleRow>
      
      <!-- Company -->
      <LabelValueRow>
        <template #label>Company</template>
        <template #value>{{ Contents.decode(inspection.company().find().name()) }}</template>
      </LabelValueRow>

      <!-- Address -->
      <LabelValueRow>
        <template #label>Address</template>
        <template #value>{{ Contents.decode(inspection.company().find().address().find().location()) }}</template>
      </LabelValueRow>

      <!-- Inspection Details -->
      <TitleRow align="left">
        Inspection Details
      </TitleRow>

      <LabelValueRow>
        <template #label>Date</template>
        <template #value>{{inspection.createdDateDisplay()}}</template>
      </LabelValueRow>

      <LabelValueRow>
        <template #label>Address</template>
        <template #value>{{ Contents.decode(inspection.location()) }}</template>
      </LabelValueRow>
      
      <LabelValueRow>
        <template #label>Trip Type</template>
        <template #value>{{ inspection.tripDisplay() }}</template>
      </LabelValueRow>
    
    
      <LabelValueRow>
        <template #label>Status</template>
        <template #value>
          <Box>
            <Row>
              <div class="rc-box-col-3 rc-input-good rc-text-centre" v-if="inspection.isDefectTypeGood()">
                {{ inspection.defectTypeDisplay() }}
              </div>
              <div class="rc-box-col-3 rc-input-minor rc-text-centre" v-else-if="inspection.isDefectTypeMinor()">
                {{ inspection.defectTypeDisplay() }}
              </div>
              <div class="rc-box-col-3 rc-input-major rc-text-centre" v-else-if="inspection.isDefectTypeMajor()">
                {{ inspection.defectTypeDisplay() }}
              </div>
              <div class="rc-box-col-6" />
            </Row>
          </Box>
        </template>
      </LabelValueRow>
      
      <!-- Vehicle -->
      <TitleRow align="left" >Vehicle </TitleRow>
      
      <Row>
        <Column>
          <Result 
            :forRepair="false"
            :initialResult="vehicleResults.initial().data" 
            :enrouteResult="vehicleResults.enroute().data"
            :inspectionData="inspection.data" />
        </Column>
      </Row>
      
      <!-- Trailer inspection -->
      <TitleRow align="left">Trailer Inspections</TitleRow>
      <Row v-if="!inspection.hasTrailers()">
        <Column v-for="trailer in inspection.trailers().list()" v-bind:key="trailer.data['@rid']">
          <Result
            :forRepair="false"
            :forVehicle="false" 
            :initialResult="trailerResults.forVehicle(trailer).initial().data" 
            :enrouteResult="trailerResults.forVehicle(trailer).enroute().data" 
            :inspectionData="inspection.data" />
        </Column>
      </Row>
      <LabelValueRow v-else>
        <template #label></template>
        <template #value>No Trailers</template>
      </LabelValueRow>  
      
      <!-- comments -->
      <TitleRow align="left">Remarks</TitleRow>
      <CommentsLayout v-if="inspection.hasComments()" :inspectionData="inspection.data" />
      <KeyValue title="newRemark"
        placeholder="Additional Remarks or Comments"
        v-on:value="setRemarkValue"
        :required="isValueRequired('newRemark')"
        :mandatory="true"
        />

      <!-- Inspector -->
      <TitleRow align="left">Initial Inspection</TitleRow>
      <LabelValueRow>
        <template #label>Inspected By</template>
        <template #value>{{inspection.inspector().find().user().find().fullName()}}</template>
      </LabelValueRow>
      
      <LabelValueRow>
        <template #label>Signature</template>
        <template #value>
          <img width="100px" :src="inspection.inspector().find().user().find().signature().find().image()" />
        </template>
      </LabelValueRow>
    
      <LabelValueRow>
        <template #label></template>
        <template #value>{{ inspection.schedule().find().nameDisplay() }}</template>
      </LabelValueRow>
      
      <!-- Driver -->
      <TitleRow align="left" >Driver</TitleRow>
      <LabelValueRow>
        <template #label>Driven By</template>
        <template #value v-if="inspection.driver().isNew()">No driver set</template>
        <template #value v-else>{{ inspection.driver().find().user().find().fullName() }}</template>
      </LabelValueRow>
  
      <LabelValueRow>
        <template #label>Signature</template>
        <template #value>
          <img width="100px" :src="inspection.driver().find().user().find().signature().find().image()" />
        </template>
      </LabelValueRow>
      <!-- /Driver -->
      
      <!-- Enroute reporter -->
      <Row v-if="inspection.hasEnrouteInspector()">
        <Column>
          <Box>
            <TitleRow align="left"> Enroute/In Trip Inspection </TitleRow>
            
            <LabelValueRow>
              <template #label>Date</template>
              <template #value>{{ inspection.enrouteInspectionDateDisplay() }}</template>
            </LabelValueRow>
            
            <LabelValueRow>
              <template #label>Inspected By</template>
              <template #value>{{ inspection.enrouteInspector().find().user().find().fullName() }}</template>
            </LabelValueRow>
             
            <LabelValueRow>
              <template #label>Signature</template>
              <template #value>
                <img width="100px" :src="inspection.enrouteInspector().find().user().find().signature().find().image()" />
              </template>
            </LabelValueRow>
            
            <LabelValueRow>
              <template #label></template>
              <template #value></template>
            </LabelValueRow>
          </Box>
        </Column>
      </Row>
    
      <!-- Repaired By -->
      <Row v-if="inspection.hasRepairs()">
        <Column :width="15">
          <Box>
            <RepairResultDetail :inspectionData="inspection.data" />
          </Box>
        </Column>
      </Row>
      
    </Box>

    <Box :showBorder="true" v-if="!isLoadingData" >
      
      <Row>
        <div class="rc-box-col-3"> </div>
        <div class="rc-box-col-9">
          <div class="container">
            <SaveRow :disabled="isSaving" v-on:value="saveRepairs" />
          </div>
        </div>
        <div class="rc-box-col-3"> </div>
      </Row>
      
    </Box>
    
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import DateUtils from '@/utils/DateUtils.js';
import MC from "@/domain/session/MC.js";

import KeyValue from '@/components/input/KeyValue.vue'

import ErrorRow from '@/components/row/ErrorRow.vue';
import SaveRow from '@/components/row/SaveRow.vue';
import SuccessRow from '@/components/row/SuccessRow.vue';
//import ProgressRow from '@/components/row/ProgressRow.vue';

import InspectionPdfLink from '@/views/portal/inspections/components/InspectionPdfLink.vue'
import CommentsLayout from '@/portals/shared/views/comments/CommentsLayout.vue'
import Result from '@/views/portal/results/ResultDetails.vue'
import RepairResultDetail from '@/views/portal/repairs/result/RepairResultDetail.vue'

import Catalog from '@/domain/session/Catalog.js'
import Signature from '@/domain/model/user/Signature.js'
//import Repair from '@/domain/model/answer/repair/Repair.js'
//import RepairResult from '@/domain/model/inspection/repair/result/RepairResult.js'
//import RepairMap from '@/domain/model/answer/repair/RepairMap.js'
//import AnswerMap from '@/domain/model/answer/AnswerMap.js'
import ResultMap from '@/domain/model/inspection/result/ResultMap.js'
import Inspection from '@/domain/model/inspection/Inspection.js';
import Comment from '@/domain/model/comment/Comment.js';

import Box    from '@/portals/shared/library/box/Box.vue';
import Row    from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';
import Button from '@/portals/shared/library/button/Button.vue';

import SpacerRow from '@/portals/shared/library/spacer/SpacerRow.vue';
//import Spacer from '@/portals/shared/library/spacer/Spacer.vue';

//import Title from '@/portals/shared/library/title/Title.vue';
import TitleRow from '@/portals/shared/library/title/TitleRow.vue';
import LabelValueRow from '@/portals/shared/library/labelvalue/LabelValueRow.vue';
//import ValueRow from '@/portals/shared/library/value/ValueRow.vue';
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

export default {
  name: 'portal-inspection-repairs',
  components: {
    Box,Row, Column,
    LabelValueRow, TitleRow,
    ErrorRow,
    SpacerRow, 
    LoadingRow,
    SuccessRow,
    KeyValue,
    SaveRow,
    Result,
    Button,
    RepairResultDetail,
    CommentsLayout,
    InspectionPdfLink,
  },
  props: {
    inspectionId: { type: String, default: null },
    emitActions:  { type: Boolean, default: false },
  },
  data() {
    return {
      id: this.$route.params['id'] ? this.$route.params['id'] : this.inspectionId,
      questions: [],
      allResults: {},
      vehicleResults: {},
      trailerResults: {},
      event: {},
      userData: {},
      error: null,
      message: null,
      isLoadingData: true,
      signature: null,
      C: new Catalog(),
      inspectionData: null,
      isSaving: false,
      workOrder: "",
      remark: "",
      remarkRequired: false,
      missing: null,
      Strings: StringUtils,
      Contents: ContentUtils,
      
      isLoading: true,
      loadingMessage: null,
      
      MC: new MC(),
      Consts: ConstUtils,
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
    itemId: function() {
      return this.$route.params['id'];
    },
    
    inspection: function() {
      if (this.id && this.domain) {
        return this.domain.inspections().findById(this.id);
      }
      return new Inspection(this.domain, {});
    },
    
    repairUserName: function() {
      return this.domain.session().user().find().fullName();
    },
    
    repairDate: function() {
      return DateUtils.toDateTime(DateUtils.now());
    },
    
    repairUserSignature: function() {
      if (this.sigature) {
        return this.signature.image();
      }
      return null;
    },
    changeBackground: function() {
      if (this.signatureRequired) {
        return "rc-box-col-12 rc-background-minor";
      }
      return "rc-box-col-12";
    }
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    },
    inspectionId() {
      this.id = this.inspectionId;
      this.queryInspectionData();
    },
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
    
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),
    start: function() {
      this.isLoadingData = false;
      this.loadingMessage = this.MC.Status.Done.value();
      
      this.queryInspectionData()
    },
    callback: function(msg) {
      this.loadingMessage = msg
    },
    refresh: function () {
      this.isLoadingData = true;
      this.queryInspectionData();
    },
    queryInspectionData: function () {
      this.isLoadingData = true;
      this.loadingMessage = "Loading Inspection Details...";
      this.allResults = {};
      this.resultAnswers = {};
      this.answerRepairs = {};
      this.signature = new Signature(this.domain);
      
      var event = this.domain.events().inspectionDetails(this.id);
      event.send(this.listener);
    },
    listener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        var map = payload.valuesFor(ResultMap.MODEL_NAME);
        this.allResults = new ResultMap(this.domain, map);
        this.vehicleResults = this.allResults.vehicle();
        this.trailerResults = this.allResults.trailers();
        this.vehicle = this.inspection.vehicle().find();
      }
      this.isLoadingData = false;
    },
    toDateTime: function (time) {
      return DateUtils.toDateTime(time)
    },
    isEven: function (position) {
      return StringUtils.isEven(position)
    },
    saveRepairs: function() {
      if (this.auth_connected) {
        this.send();
      } else {
        this.$connect();  
      }
    },
    requirementsMet: function() {
      var met = true;
      if (met && StringUtils.isEmpty(this.remark)) {
        this.missing = "newRemark";
        met = false;
      }
      return met;
    },
    send: function() {
      if (!this.requirementsMet()) {
        return false;
      }
      var inspection = this.inspection.copy();
      
      var session = this.domain.session();
      var employee = session.employee();
      var user = session.user();
      
      if (!StringUtils.isEmpty(this.remark)) {
        var comment = new Comment(this.domain);
        comment
          .withText(this.remark)
          .with(employee)
          .withCreatedDateNow()
          .withCreatedBy(user)
        var comments = inspection.comments();
        comments.add(comment);
        inspection.with(comments);
      }
      
      this.isSaving = true;
      this.message = "Saving remarks please wait...";
      var event = this.domain.events().inspections().save(inspection);
      event.send(this.saveListener);
      return true;
    },
    saveListener: function(response) {
      this.isSaving = false;
      if (response.error()) {
        this.error = response.error();
      } else {
        this.message = "Inspection remarks have been successfully saved!";
        
        if (this.emitActions) {
          window.scrollTo(0, 0);
          this.$emit("handleRemarkSaveAction");
        } else {
          var params = {
            id: this.inspection.id(),
          }
          this.$router.push({
            name: ConstUtils.ROUTES.INSPECTION_DETAILS,
            params: params,
          });
        }
      }
    },
    setValue: function(kvp) {
      this.workOrder = kvp.value;
    },
    setRemarkValue: function(kvp) {
      this.remark = kvp.value;
    },
    isValueRequired: function(value) {
      return this.missing === value;
    },

  },
}
</script>