<template>

  <div v-if="hasPdfUrl">
    <a :href="pdfUrl" 
        target="_blank" 
        :disabled="disabled"
        class="rc-input-button-nosize rc-input-width rc-font-small rc-text-label">Download</a>
  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import ContentUtils from '@/utils/ContentUtils.js'
import Inspection from '@/domain/model/inspection/Inspection.js'

export default {
  name: 'inspection-pdf-link',
  components: {
  },
  props: {
    inspectionId: { type: String, default: null },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
    }
  },
  watch: {
    inspectionId() {
      this.domain.logger().info("inspection changed");
    }
  },
  computed: {
    ...mapGetters([
      'domain',
    ]),
    inspection: function() {
      if (this.domain && this.inspectionId) {
        return this.domain.inspections().findById(this.inspectionId);
      }
      return new Inspection(this.domain);
    },
    pdfUrl: function() {
      if (!this.domain) {
        return "";
      }
      var url = ContentUtils.escape(this.inspection.pdfUrl());
      url = url.replace("#", "%23");
      return url;
    },
    hasPdfUrl: function() {
      if (!this.domain) {
        return false;
      }
      return this.inspection.hasPdfUrl();
    },
  },
  methods: {
    
  }
}
</script>